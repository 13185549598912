import React, { useEffect, useState } from 'react';
import post from '../../data/FacebookPage.json';
import './Post.css';
declare global {
    interface Window {
        FB: any;
    }
}

const FacebookPosts: React.FC = () => {
    const [key, setKey] = useState(0);
    const width = window.innerWidth < 360 ? window.innerWidth : 360;
    useEffect(() => {
        const delayUsingSetTimeout = setTimeout(() => {
            const element = document.getElementsByClassName('fb-post')[0];
            // ตรวจสอบว่ามี iframe หรือไม่ และ iframe มี childElementCount ตามที่คาดไว้หรือไม่
            if ((element && element.childElementCount === 0) || document.getElementsByClassName('fb_iframe_widget').length < 4) {
                if (window.FB) {
                    window.FB.XFBML.parse(); // Render ใหม่สำหรับ fb-post
                }
                setKey((prevKey) => prevKey + 1); // อัปเดต key ให้ถูกต้อง
                console.log('key updated to:', key + 1);
            }
        }, 5000); // รอ 5 วินาทีแล้วตรวจสอบ

        return () => clearTimeout(delayUsingSetTimeout); // ล้าง setTimeout เมื่อคอมโพเนนต์ถูก unmount
    }, [key]); // ทำงานทุกครั้งที่ key เปลี่ยน

    return (
        <div className={"div-fb-post"}>
            <iframe
                title="fb-page"
                className='fb-page'
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100066915382208&tabs=timeline&width=500&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=610402890929405"
                width="500" height="130"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            <h2>Post</h2>
            <script async defer src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"></script>
            <div className={"flex-post"}>
                <div className="group-post">
                    {post.map((url, index) => {
                        return (

                            <div key={key + index}
                                className="fb-post"
                                data-href={url}
                                data-width={width}
                                style={index === post.length ? { display: 'none' } : undefined}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FacebookPosts;
